export default {
  state: {
    list: [],
  },
  mutations: {
  },
  actions: {
    exportChatRooms({ commit, state }, query) {
      return this._vm.$api.omni.rooms.export(query);
    },
    fetchChatReports({ commit, state }, query) {
      return this._vm.$api.omni.reports.fetch(query);
    },
    storeRoom({ commit, state }, payload) {
      return this._vm.$api.omni.rooms.store(payload);
    },
    storeResumeMessage({ commit, state }, payload) {
      return this._vm.$api.omni.messages.store(payload);
    },
  },
};
