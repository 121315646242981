export default [
  {
    name: 'Users',
    path: 'users',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/users/Users.vue') : import('../views/users/Users.vue')),
  },
  {
    name: 'InviteUser',
    path: 'users/invite',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/users/InviteOrEdit.vue') : import('../views/users/InviteOrEdit.vue')),
  },
  {
    name: 'UsersEdit',
    path: 'users/edit/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/users/InviteOrEdit.vue') : import('../views/users/InviteOrEdit.vue')),
  },
];
