import planRoutes from './admin/plans';
import customerRoutes from './admin/customers';

const routes = [
  {
    name: 'AdminAntifraud',
    path: 'antifraud',
    component: () => (window.$rebranding.active ? import('../views/rebranding/admin/Antifraud.vue') : import('../views/admin/Antifraud.vue')),
  },
  {
    name: 'AdminInvoices',
    path: 'invoices',
    component: () => import('../views/admin/Invoices.vue'),
  },
  {
    name: 'AdminProviders',
    path: 'providers',
    component: () => import('../views/admin/providers/Index.vue'),
  },
  {
    name: 'CreateProvider',
    path: 'providers/create',
    component: () => import('../views/admin/providers/Create.vue'),
  },
  {
    name: 'ShowProvider',
    path: 'providers/:id',
    component: () => import('../views/admin/providers/Show.vue'),
  },
  {
    name: 'ShortCodes',
    path: 'short-codes',
    component: () => import('../views/admin/short-codes/Index.vue'),
  },
  {
    name: 'CreateShortCodes',
    path: 'short-codes/create',
    component: () => import('../views/admin/short-codes/Create.vue'),
  },
  {
    name: 'ShowShortCodes',
    path: 'short-codes/:id',
    component: () => import('../views/admin/short-codes/Show.vue'),
  },
  {
    name: 'AdminProducts',
    path: 'products',
    component: () => import('../views/admin/products/Index.vue'),
  },
  {
    name: 'CreateProduct',
    path: 'products/create',
    component: () => import('../views/admin/products/Create.vue'),
  },
  {
    name: 'ShowProduct',
    path: 'products/:id',
    component: () => import('../views/admin/products/Show.vue'),
  },
  {
    name: 'AdminTickets',
    path: 'tickets',
    component: () => import('../views/admin/tickets/Index.vue'),
  },
  // {
  //   name: 'OpenTicket',
  //   path: 'support/new-ticket',
  //   component: () => import('../views/support/OpenTicket.vue'),
  // },
  {
    name: 'ViewTicket',
    path: 'support/:id',
    component: () => import('../views/admin/tickets/View.vue'),
  },
  // Admin SMS router hierarchy
  {
    name: 'AdminSmsReports',
    path: 'sms/reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/admin/sms/Index.vue'),
    children: [
      {
        name: 'AdminSmsStatReport',
        path: 'stat',
        component: () => import('../views/admin/sms/Stat.vue'),
      },
      {
        name: 'AdminSmsSinteticReport',
        path: 'insights',
        component: () => import('../views/admin/sms/Insights.vue'),
      },
      {
        name: 'AdminSmsHistoryReport',
        path: 'history',
        component: () => import('../views/admin/sms/History.vue'),
      },
      {
        name: 'SmsDetail',
        path: 'history/:id',
        component: () => import('@/views/rebranding/sms/reports/Detail.vue'),
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
      },
    ],
  },
  {
    name: 'AdminSmsDashboard',
    path: 'sms/dashboard',
    component: () => import('../views/admin/sms/Dashboard.vue'),
  },
  {
    name: 'AdminSmsMonitor',
    path: 'sms/monitor',
    component: () => import('../views/admin/sms/Monitor.vue'),
  },
  {
    name: 'AdminSmsSales',
    path: 'sms/sales',
    component: () => import('../views/admin/sms/Sales.vue'),
  },
  {
    name: 'AdminEmailReports',
    path: 'email/reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/admin/email/Index.vue'),
    children: [
      {
        name: 'AdminEmailStatReport',
        path: 'stat',
        component: () => import('../views/admin/email/Stat.vue'),
      },
      {
        name: 'AdminEmailHistoryReport',
        path: 'history',
        component: () => import('../views/admin/email/History.vue'),
      },
      {
        name: 'AdminEmailUsageReport',
        path: 'usage',
        component: () => import('../views/admin/email/Usage.vue'),
      },
      {
        name: 'AdminEmailSinteticReport',
        path: 'insights',
        component: () => import('../views/admin/email/Insights.vue'),
      },
    ],
  },
  {
    name: 'AdminWhatsappReports',
    path: 'whatsapp/reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/admin/whatsapp/Index.vue'),
    children: [
      {
        name: 'AdminWhatsappStatReport',
        path: 'stat',
        component: () => import('../views/admin/whatsapp/Stat.vue'),
      },
      {
        name: 'AdminWhatsappHistoryReport',
        path: 'history',
        component: () => import('../views/admin/whatsapp/History.vue'),
      },
      {
        name: 'AdminWhatsappSinteticReport',
        path: 'insights',
        component: () => import('../views/admin/whatsapp/Insights.vue'),
      },
    ],
  },
  {
    name: 'AdminRcsReports',
    path: 'rcs/reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/admin/rcs/Index.vue'),
    children: [
      {
        name: 'AdminRcsStatReport',
        path: 'stat',
        component: () => import('../views/admin/rcs/Stat.vue'),
      },
      {
        name: 'AdminRcsHistoryReport',
        path: 'history',
        component: () => import('../views/admin/rcs/History.vue'),
      },
      {
        name: 'AdminRcsSinteticReport',
        path: 'insights',
        component: () => import('../views/admin/rcs/Insights.vue'),
      },
    ],
  },
  {
    name: 'AdminWhitelabel',
    path: 'whitelabel',
    component: () => import('../views/rebranding/admin/Whitelabel.vue'),
  },
  {
    name: 'AdminAnalytics',
    path: 'analytics',
    component: () => import('../views/rebranding/admin/Analytics.vue'),
  },
  {
    name: 'AdminAudit',
    path: 'audit',
    component: () => import('../views/rebranding/admin/audit/Index.vue'),
    children: [
      {
        name: 'AdminAuditInsights',
        path: 'insights',
        component: () => import('@/views/rebranding/admin/audit/Insights.vue'),
      },
    ],
  },
  {
    name: 'AdminLogs',
    path: 'logs',
    component: () => import('../views/rebranding/admin/logs-report/Index.vue'),
  },
  ...planRoutes,
  ...customerRoutes,
];

export default routes;
