export default [
  /* {
    name: 'Inbox',
    path: '/chat/inbox',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/chat/Inbox.vue'),
  }, */
  {
    name: 'ChatReport',
    path: 'chat/reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('@/views/chat/reports/Index.vue'),
    children: [
      {
        name: 'ChatStats',
        path: 'stat',
        component: () => import('@/views/chat/reports/Stat.vue'),
      },
      {
        name: 'OmniBusinessReportHistory',
        path: 'history',
        component: () => import('@/views/chat/reports/History.vue'),
      },
      {
        name: 'ChatRoom',
        path: 'history/:roomId',
        component: () => import('@/views/chat/reports/Room.vue'),
      },
      {
        name: 'OmniBusinessReportExport',
        path: 'exports',
        component: () => import('@/views/chat/reports/Export.vue'),
      },
    ],
  },
];
